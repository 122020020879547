/*************************************************************
 * SugarPak - Domain Specific Language -  Syntactical Sugar  *
 *************************************************************/
 
(function () {
	var $D = Date, $P = $D.prototype, $N = Number.prototype;

	// private
	$P._orient = +1;

	// private
	$P._nth = null;

	// private
	$P._is = false;

	// private
	$P._same = false;
	
	// private
	$P._isSecond = false;

	// private
	$N._dateElement = "days";

	/** 
	 * Moves the date to the next instance of a date as specified by the subsequent date element function (eg. .day(), .month()), month name function (eg. .january(), .jan()) or day name function (eg. .friday(), fri()).
	 * Example
	<pre><code>
	Date.today().next().friday();
	Date.today().next().fri();
	Date.today().next().march();
	Date.today().next().mar();
	Date.today().next().week();
	</code></pre>
	 * 
	 * @return {Date}    date
	 */
	$P.next = function () {
		this._move = true;
		this._orient = +1;
		return this;
	};

	/** 
	 * Creates a new Date (Date.today()) and moves the date to the next instance of the date as specified by the subsequent date element function (eg. .day(), .month()), month name function (eg. .january(), .jan()) or day name function (eg. .friday(), fri()).
	 * Example
	<pre><code>
	Date.next().friday();
	Date.next().fri();
	Date.next().march();
	Date.next().mar();
	Date.next().week();
	</code></pre>
	 * 
	 * @return {Date}    date
	 */
	$D.next = function () {
		return $D.today().next();
	};

	/** 
	 * Moves the date to the previous instance of a date as specified by the subsequent date element function (eg. .day(), .month()), month name function (eg. .january(), .jan()) or day name function (eg. .friday(), fri()).
	 * Example
	<pre><code>
	Date.today().last().friday();
	Date.today().last().fri();
	Date.today().last().march();
	Date.today().last().mar();
	Date.today().last().week();
	</code></pre>
	 *  
	 * @return {Date}    date
	 */
	$P.last = $P.prev = $P.previous = function () {
		this._move = true;
		this._orient = -1;
		return this;
	};

	/** 
	 * Creates a new Date (Date.today()) and moves the date to the previous instance of the date as specified by the subsequent date element function (eg. .day(), .month()), month name function (eg. .january(), .jan()) or day name function (eg. .friday(), fri()).
	 * Example
	<pre><code>
	Date.last().friday();
	Date.last().fri();
	Date.previous().march();
	Date.prev().mar();
	Date.last().week();
	</code></pre>
	 *  
	 * @return {Date}    date
	 */
	$D.last = $D.prev = $D.previous = function () {
		return $D.today().last();
	};

	/** 
	 * Performs a equality check when followed by either a month name, day name or .weekday() function.
	 * Example
	<pre><code>
	Date.today().is().friday(); // true|false
	Date.today().is().fri();
	Date.today().is().march();
	Date.today().is().mar();
	</code></pre>
	 *  
	 * @return {Boolean}    true|false
	 */
	$P.is = function () {
		this._is = true;
		return this;
	};

	/** 
	 * Determines if two date objects occur on/in exactly the same instance of the subsequent date part function.
	 * The function .same() must be followed by a date part function (example: .day(), .month(), .year(), etc).
	 *
	 * An optional Date can be passed in the date part function. If now date is passed as a parameter, 'Now' is used. 
	 *
	 * The following example demonstrates how to determine if two dates fall on the exact same day.
	 *
	 * Example
	<pre><code>
	var d1 = Date.today(); // today at 00:00
	var d2 = new Date();   // exactly now.

	// Do they occur on the same day?
	d1.same().day(d2); // true
	
	// Do they occur on the same hour?
	d1.same().hour(d2); // false, unless d2 hour is '00' (midnight).
	
	// What if it's the same day, but one year apart?
	var nextYear = Date.today().add(1).year();

	d1.same().day(nextYear); // false, because the dates must occur on the exact same day. 
	</code></pre>
	 *
	 * Scenario: Determine if a given date occurs during some week period 2 months from now. 
	 *
	 * Example
	<pre><code>
	var future = Date.today().add(2).months();
	return someDate.same().week(future); // true|false;
	</code></pre>
	 *  
	 * @return {Boolean}    true|false
	 */
	$P.same = function () {
		this._same = true;
		this._isSecond = false;
		return this;
	};

	/** 
	 * Determines if the current date/time occurs during Today. Must be preceded by the .is() function.
	 * Example
	<pre><code>
	someDate.is().today();    // true|false
	new Date().is().today();  // true
	Date.today().is().today();// true
	Date.today().add(-1).day().is().today(); // false
	</code></pre>
	 *  
	 * @return {Boolean}    true|false
	 */
	$P.today = function () {
		return this.same().day();
	};

	/** 
	 * Determines if the current date is a weekday. This function must be preceded by the .is() function.
	 * Example
	<pre><code>
	Date.today().is().weekday(); // true|false
	</code></pre>
	 *  
	 * @return {Boolean}    true|false
	 */
	$P.weekday = function () {
		if (this._nth) {
			return df("Weekday").call(this);
		}
		if (this._move) {
			return this.addWeekdays(this._orient);
		}
		if (this._is) {
			this._is = false;
			return (!this.is().sat() && !this.is().sun());
		}
		return false;
	};
	/** 
	 * Determines if the current date is on the weekend. This function must be preceded by the .is() function.
	 * Example
	<pre><code>
	Date.today().is().weekend(); // true|false
	</code></pre>
	 *  
	 * @return {Boolean}    true|false
	 */
	$P.weekend = function () {
		if (this._is) {
			this._is = false;
			return (this.is().sat() || this.is().sun());
		}
		return false;
	};

	/** 
	 * Sets the Time of the current Date instance. A string "6:15 pm" or config object {hour:18, minute:15} are accepted.
	 * Example
	<pre><code>
	// Set time to 6:15pm with a String
	Date.today().at("6:15pm");

	// Set time to 6:15pm with a config object
	Date.today().at({hour:18, minute:15});
	</code></pre>
	 *  
	 * @return {Date}    date
	 */
	$P.at = function (time) {
		return (typeof time === "string") ? $D.parse(this.toString("d") + " " + time) : this.set(time);
	};
		
	/** 
	 * Creates a new Date() and adds this (Number) to the date based on the preceding date element function (eg. second|minute|hour|day|month|year).
	 * Example
	<pre><code>
	// Undeclared Numbers must be wrapped with parentheses. Requirment of JavaScript.
	(3).days().fromNow();
	(6).months().fromNow();

	// Declared Number variables do not require parentheses. 
	var n = 6;
	n.months().fromNow();
	</code></pre>
	 *  
	 * @return {Date}    A new Date instance
	 */
	$N.fromNow = $N.after = function (date) {
		var c = {};
		c[this._dateElement] = this;
		return ((!date) ? new Date() : date.clone()).add(c);
	};

	/** 
	 * Creates a new Date() and subtract this (Number) from the date based on the preceding date element function (eg. second|minute|hour|day|month|year).
	 * Example
	<pre><code>
	// Undeclared Numbers must be wrapped with parentheses. Requirment of JavaScript.
	(3).days().ago();
	(6).months().ago();

	// Declared Number variables do not require parentheses. 
	var n = 6;
	n.months().ago();
	</code></pre>
	 *  
	 * @return {Date}    A new Date instance
	 */
	$N.ago = $N.before = function (date) {
		var c = {},
		s = (this._dateElement[this._dateElement.length-1] !== "s") ? this._dateElement + "s" : this._dateElement;
		c[s] = this * -1;
		return ((!date) ? new Date() : date.clone()).add(c);
	};

	// Do NOT modify the following string tokens. These tokens are used to build dynamic functions.
	// All culture-specific strings can be found in the CultureInfo files.
	var dx = ("sunday monday tuesday wednesday thursday friday saturday").split(/\s/),
		mx = ("january february march april may june july august september october november december").split(/\s/),
		px = ("Millisecond Second Minute Hour Day Week Month Year Quarter Weekday").split(/\s/),
		pxf = ("Milliseconds Seconds Minutes Hours Date Week Month FullYear Quarter").split(/\s/),
		nth = ("final first second third fourth fifth").split(/\s/),
		de;

   /** 
	 * Returns an object literal of all the date parts.
	 * Example
	<pre><code>
	var o = new Date().toObject();
	
	// { year: 2008, month: 4, week: 20, day: 13, hour: 18, minute: 9, second: 32, millisecond: 812 }
	
	// The object properties can be referenced directly from the object.
	
	alert(o.day);  // alerts "13"
	alert(o.year); // alerts "2008"
	</code></pre>
	 *  
	 * @return {Date}    An object literal representing the original date object.
	 */
	$P.toObject = function () {
		var o = {};
		for (var i = 0; i < px.length; i++) {
			if (this["get" + pxf[i]]) {
				o[px[i].toLowerCase()] = this["get" + pxf[i]]();
			}
		}
		return o;
	};
   
   /** 
	 * Returns a date created from an object literal. Ignores the .week property if set in the config. 
	 * Example
	<pre><code>
	var o = new Date().toObject();
	
	return Date.fromObject(o); // will return the same date. 

	var o2 = {month: 1, day: 20, hour: 18}; // birthday party!
	Date.fromObject(o2);
	</code></pre>
	 *  
	 * @return {Date}    An object literal representing the original date object.
	 */
	$D.fromObject = function(config) {
		config.week = null;
		return Date.today().set(config);
	};
		
	// Create day name functions and abbreviated day name functions (eg. monday(), friday(), fri()).
	
	var df = function (n) {
		return function () {
			if (this._is) {
				this._is = false;
				return this.getDay() === n;
			}
			if (this._move) { this._move = null; }
			if (this._nth !== null) {
				// If the .second() function was called earlier, remove the _orient 
				// from the date, and then continue.
				// This is required because 'second' can be used in two different context.
				// 
				// Example
				//
				//   Date.today().add(1).second();
				//   Date.march().second().monday();
				// 
				// Things get crazy with the following...
				//   Date.march().add(1).second().second().monday(); // but it works!!
				//  
				if (this._isSecond) {
					this.addSeconds(this._orient * -1);
				}
				// make sure we reset _isSecond
				this._isSecond = false;

				var ntemp = this._nth;
				this._nth = null;
				var temp = this.clone().moveToLastDayOfMonth();
				this.moveToNthOccurrence(n, ntemp);
				if (this > temp) {
					throw new RangeError($D.getDayName(n) + " does not occur " + ntemp + " times in the month of " + $D.getMonthName(temp.getMonth()) + " " + temp.getFullYear() + ".");
				}
				return this;
			}
			return this.moveToDayOfWeek(n, this._orient);
		};
	};
	
	var sdf = function (n) {
		return function () {
			var t = $D.today(), shift = n - t.getDay();
			if (n === 0 && Date.CultureInfo.firstDayOfWeek === 1 && t.getDay() !== 0) {
				shift = shift + 7;
			}
			return t.addDays(shift);
		};
	};
	

	
	// Create month name functions and abbreviated month name functions (eg. january(), march(), mar()).
	var month_instance_functions = function (n) {
		return function () {
			if (this._is) {
				this._is = false;
				return this.getMonth() === n;
			}
			return this.moveToMonth(n, this._orient);
		};
	};
	
	var month_static_functions = function (n) {
		return function () {
			return $D.today().set({ month: n, day: 1 });
		};
	};
	
	var processTerms = function (names, staticFunc, instanceFunc) {
		for (var i = 0; i < names.length; i++) {
			// Create constant static Name variables.
			$D[names[i].toUpperCase()] = $D[names[i].toUpperCase().substring(0, 3)] = i;
			// Create Name functions.
			$D[names[i]] = $D[names[i].substring(0, 3)] = staticFunc(i);
			// Create Name instance functions.
			$P[names[i]] = $P[names[i].substring(0, 3)] = instanceFunc(i);
		}

	};

	processTerms(dx, sdf, df);
	processTerms(mx, month_static_functions, month_instance_functions);
	
	// Create date element functions and plural date element functions used with Date (eg. day(), days(), months()).
	var ef = function (j) {
		return function () {
			// if the .second() function was called earlier, the _orient 
			// has alread been added. Just return this and reset _isSecond.
			if (this._isSecond) {
				this._isSecond = false;
				return this;
			}

			if (this._same) {
				this._same = this._is = false;
				var o1 = this.toObject(),
					o2 = (arguments[0] || new Date()).toObject(),
					v = "",
					k = j.toLowerCase();

				// the substr trick with -1 doesn't work in IE8 or less
				k = (k[k.length-1] === "s") ? k.substring(0,k.length-1) : k;
					
				for (var m = (px.length - 1); m > -1; m--) {
					v = px[m].toLowerCase();
					if (o1[v] !== o2[v]) {
						return false;
					}
					if (k === v) {
						break;
					}
				}
				return true;
			}
			
			if (j.substring(j.length - 1) !== "s") {
				j += "s";
			}
			if (this._move) { this._move = null; }
			return this["add" + j](this._orient);
		};
	};
	
	
	var nf = function (n) {
		return function () {
			this._dateElement = n;
			return this;
		};
	};
   
	for (var k = 0; k < px.length; k++) {
		de = px[k].toLowerCase();
		if(de !== "weekday") {
			// Create date element functions and plural date element functions used with Date (eg. day(), days(), months()).
			$P[de] = $P[de + "s"] = ef(px[k]);
			
			// Create date element functions and plural date element functions used with Number (eg. day(), days(), months()).
			$N[de] = $N[de + "s"] = nf(de + "s");
		}
	}
	
	$P._ss = ef("Second");
	
	var nthfn = function (n) {
		return function (dayOfWeek) {
			if (this._same) {
				return this._ss(arguments[0]);
			}
			if (dayOfWeek || dayOfWeek === 0) {
				return this.moveToNthOccurrence(dayOfWeek, n);
			}
			this._nth = n;

			// if the operator is 'second' add the _orient, then deal with it later...
			if (n === 2 && (dayOfWeek === undefined || dayOfWeek === null)) {
				this._isSecond = true;
				return this.addSeconds(this._orient);
			}
			return this;
		};
	};

	for (var l = 0; l < nth.length; l++) {
		$P[nth[l]] = (l === 0) ? nthfn(-1) : nthfn(l);
	}
}());
